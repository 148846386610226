/*======= flip-box ==========*/
.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  @include border-radius(8px);
  margin-bottom: 30px;
  .flip-img {
    @include border-radius(8px);
    width: 100%;
  }
  .flip-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 25px;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    @include transform(translateZ(60px) scale(.88));
    color: $white;
  }
  .flip-title {
    color: $white;
    font-size: $font-size-20;
    font-weight: $font-weight-semi-bold;
  }
  &:hover{
    .flip-box-front {
      @include transform(rotateY(-180deg));
      -webkit-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
    .flip-box-back {
      @include transform(rotateY(0deg));
      -webkit-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      transform-style: preserve-3d;
    }
  }
}

.flip-box-front,
.flip-box-back {
  @include border-radius(8px);
  -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $black;
    @include border-radius(8px);
    opacity: 0.6;
  }
}
.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  @include transform(rotateY(180deg));
}