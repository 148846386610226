/*======= generic-table ========*/
.generic-table {
  color: $theme-color;
  margin-bottom: 0;
  thead {
    background-color: rgba(127, 126, 151, 0.1);
    th {
      border: none;
      font-weight: $font-weight-semi-bold;
    }
  }
  tbody {
    th,
    td {
      padding-top: 20px;
      padding-bottom: 20px;
      border-top: none;
      vertical-align: middle;
      font-weight: $font-weight-medium;
      border-bottom: 1px solid rgba(127, 126, 151, 0.1);
    }
  }
}