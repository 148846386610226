$theme-color: #233d63;
$theme-color-2: #ec5252;
$theme-color-3: #ef6767;
$theme-color-4: #7f8897;

$theme-font: 'Inter', sans-serif;

/*==== font size ====*/
$font-size-90: 90px;
$font-size-80: 80px;
$font-size-70: 70px;
$font-size-65: 65px;
$font-size-60: 60px;
$font-size-50: 50px;
$font-size-45: 45px;
$font-size-40: 40px;
$font-size-35: 35px;
$font-size-30: 30px;
$font-size-28: 28px;
$font-size-26: 26px;
$font-size-24: 24px;
$font-size-22: 22px;
$font-size-20: 20px;
$font-size-19: 19px;
$font-size-18: 18px;
$font-size-17: 17px;
$font-size-16: 16px;
$font-size-15: 15px;
$font-size-14: 14px;
$font-size-13: 13px;
$font-size-12: 12px;
$font-size-11: 11px;

/*==== font weight ====*/
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;

/*==== color ====*/
$white: #fff;
$black: #000;
$black-2: #1b1b1b;
$black-3: #292929;
$black-4: #3a3a3a;
$color-gray: #f7f7ff;
$color-gray-2: #eeeeee;
$color-1: #7E3CF9;
$color-2: #F68A03;
$color-3: #358FF7;
$color-4: #38BB0C;
$color-5: #DD493D;
$color-6: #3E5B99;
$color-7: #3AAAE1;
$color-8: #FF0000;
$color-9: #28a745;
$color-10: #f1e77f;

$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1199px)';
$laptop_m_four: '(max-width: 1024px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';

$device-480px: '(max-width: 480px)';
$device-375px: '(max-width: 375px)';
$device-320px: '(max-width: 320px)';