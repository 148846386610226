/*====== sidebar-negative =======*/
.sidebar-negative {
  position: relative;
  z-index: 3;
  margin-top: -100px;
  @media #{$tab_device} {
    margin-top: 0;
  }
  @media #{$large_mobile} {
    margin-top: 0;
  }
  @media #{$small_mobile} {
    margin-top: 0;
  }
}
/*====== widget-panel =======*/
.widget-panel {
  margin-bottom: 30px;
}
.preview-course-video {
  position: relative;
  .overlay {
    background: linear-gradient(180deg,rgba(30,30,28,0) 0%,rgba(30,30,28,0.9) 100%);
    @include border-radius(4px);
    z-index: -1;
  }
  &:hover {
    .play-button {
      @include transform(scale(1.1));
    }
  }
}
.preview-course-video-content {
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 20px;
  z-index: 1;
}
.play-button {
  @include transition(0.3s);
}